import React, { ReactNode, useEffect } from 'react';
import { FormField, FormWrapper } from '../common/Form';
import { useFormik } from 'formik';
import Button from '../common/Button';
import { OptionType } from '../../constants/select';
import { formatAsCurrency, getEventDateRange } from '../../util';
import {
  EventTicketType,
  OwningEntity
} from '../members/NewApiTypes.generated';
import { EventSectionType } from '../../helpers/eventSections.helper';
import { formatDateRange } from '../../util/date';

export interface EventSectionsCartModel {
  display: boolean;
  updatedAt: string;
  items: EventSectionsTicketModel[];
}

export interface EventSectionsTicketModel {
  id: string;
  numberOfTickets: number;
  minTickets: number;
  maxTickets: number;
  title: string;
  dates: string[] | Date[];
  ticketType: EventTicketType;
  allTicketTypes: EventTicketType[];
  owningEntity: OwningEntity;
  type: EventSectionType;
}

interface ConferenceDaysFormProps {
  setCart: any;
  cart: EventSectionsCartModel;
  onSubmit: () => void;
  submitText?: string;
  loading?: boolean;
  disabled?: boolean;
  hideSubmit?: boolean;
  additionalButtons?: ReactNode;
}

const EventSectionsForm = ({
  hideSubmit,
  setCart,
  cart,
  onSubmit,
  submitText,
  loading,
  disabled,
  additionalButtons
}: ConferenceDaysFormProps) => {
  // @ts-ignore
  const getInitialValues = () =>
    cart?.items?.length
      ? cart.items.reduce(
          (
            accum: { [key: string]: number },
            item: EventSectionsTicketModel
          ) => {
            accum[item.id] = item.numberOfTickets;
            return accum;
          },
          {}
        )
      : {};
  const formik = useFormik<any>({
    initialValues: getInitialValues(),
    onSubmit: values => {
      onSubmit();
    }
  });

  useEffect(() => {
    formik.setValues(getInitialValues());
  }, [cart]);

  const removeItem = (id: string) => {
    const index = cart.items.findIndex(item => item.id === id);
    if (index !== -1) {
      const items = cart.items
        .slice(0, index)
        .concat(cart.items.slice(index + 1, cart.items.length));
      setCart({
        ...cart,
        items,
        display: !!items?.length,
        updatedAt: new Date().toISOString()
      });
    }
  };

  const getOptions = (item: EventSectionsTicketModel) => {
    const options: OptionType[] = [];
    for (let i = item.minTickets || 0; i <= item.maxTickets || 0; i++) {
      options.push({
        value: i,
        label: i
      });
    }
    return options;
  };

  const ticketsAmountChanged = (id: string, event: OptionType) => {
    const ticketIndex = cart.items.findIndex(c => c.id === id);
    if (Number(event.value) > 0) {
      cart.items[ticketIndex].numberOfTickets = Number(event.value);
      setCart({
        ...cart,
        display: !!cart.items.length,
        updatedAt: new Date().toISOString()
      });
    } else {
      const items = [...cart.items.filter(c => c.id !== id)];
      setCart({
        ...cart,
        items,
        display: !!items.length,
        updatedAt: new Date().toISOString()
      });
    }
  };
  return (
    <FormWrapper formik={formik} hideActionButtons={true}>
      {cart.items.map(item => (
        <div key={item.id} className="mb-2 pb-2 border-b">
          <div className="text-primary text-xs mb-2">{item.title}</div>
          {item.dates?.length && (
            <div className="text-primary text-xs mb-2">
              {formatDateRange(item.dates as string[])}
            </div>
          )}
          <div className="flex items-end">
            <FormField<any>
              name={item.id}
              type="select"
              label="NUMBER OF TICKETS"
              placeholder="Number of tickets"
              options={getOptions(item)}
              onChange={value => ticketsAmountChanged(item.id, value)}
              className="w-40 mr-4"
            />
            <span
              className="mb-2 text-red-500 cursor-pointer sans-serif"
              onClick={() => removeItem(item.id)}
            >
              Remove
            </span>
          </div>
          <div className="my-1 text-xs italic text-gray-500">
            Subtotal:{' '}
            {formatAsCurrency(
              (item.ticketType.price * item.numberOfTickets) / 100
            )}
          </div>
        </div>
      ))}
      <div>
        <div className="mb-1 italic text-gray-500">
          Total:{' '}
          {formatAsCurrency(
            cart.items.reduce(
              (accum, item) =>
                (accum += item.ticketType.price * item.numberOfTickets),
              0
            ) / 100
          )}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <span
          className="text-red-500 cursor-pointer sans-serif mr-4"
          onClick={() =>
            setCart({
              items: [],
              display: false,
              updatedAt: new Date().toISOString()
            })
          }
        >
          Remove all
        </span>

        {!hideSubmit && (
          <Button
            isLoading={loading}
            type="submit"
            disabled={loading || disabled}
            text={submitText || 'Checkout'}
          />
        )}
        {additionalButtons}
      </div>
    </FormWrapper>
  );
};

export default EventSectionsForm;
