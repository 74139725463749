import React from 'react';
import {
  FaFacebook,
  FaInstagramSquare,
  FaTwitter,
  FaYoutube
} from 'react-icons/fa';

const SocialLinks = () => {
  return (
    <div className="text-gray-500 text-5xl flex">
      <div className="mr-8">
        <a href="https://twitter.com/TCAdvancingLit" target="_blank">
          <span className="sr-only">Twitter/X</span>
          <FaTwitter />
        </a>
      </div>
      <div className="mr-8">
        <a href="https://www.facebook.com/TCAdvancingLit" target="_blank">
          <span className="sr-only">Facebook</span>
          <FaFacebook />
        </a>
      </div>
      <div className="mr-8">
        <a href="https://www.instagram.com/tcadvancinglit" target="_blank">
          <span className="sr-only">Instagram</span>
          <FaInstagramSquare />
        </a>
      </div>
      <div className="mr-8">
        <a href="https://youtube.com/c/tcrwp/" target="_blank">
          <span className="sr-only">YouTube</span>
          <FaYoutube />
        </a>
      </div>
    </div>
  );
};

export default SocialLinks;
