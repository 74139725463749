import React from 'react';
import SocialLinks from './SocialLinks';

const footerLinkGroups = [
  {
    title: 'Organization',
    links: [
      { title: 'Our People', link: '/our-people' },
      { title: 'Contact', link: '/contact' },
      // { title: 'Get Started', link: '/get-started' },
      { title: 'News', link: '/news' },
      { title: 'Blog', link: '/blog' }
    ]
  },
  {
    title: 'Services',
    links: [
      { title: 'Events', link: '/events' },
      { title: 'Partnering with Advancing Literacy', link: '/services' }
    ]
  },
  {
    title: 'Resources',
    links: [
      { title: 'Videos', link: '/resources' },
      { title: 'Curricular Materials', link: '/resources' },
      {
        title: 'Assessments',
        link: '/resources'
      },
      {
        title: 'Results in Partner Schools',
        link: '/about'
      }
      // {
      //   title: 'Job Board',
      //   link: '/job-board'
      // }
    ]
  }
];

interface FooterLinkProps {
  title: string;
  link: string;
}

interface FooterLinkHeaderProps {
  title: string;
}

const FooterLinkHeader = (props: FooterLinkHeaderProps) => (
  <p className="text-primary font-semibold pb-2 sans-serif text-lg">
    {props.title}
  </p>
);

const FooterLink = (props: FooterLinkProps) => {
  return (
    <a
      href={props.link}
      className="text-gray-500 py-2 px-4 ml-2 sans-serif text-lg"
    >
      {props.title}
    </a>
  );
};

interface FooterLink {
  title: string;
  link: string;
}

interface FooterLinkGroup {
  title: string;
  links: FooterLink[];
}

interface FooterLinkGroupProps {
  group: FooterLinkGroup;
}

const FooterLinkGroup = (props: FooterLinkGroupProps) => (
  <div className="flex flex-col ">
    <FooterLinkHeader title={props.group.title} />
    {props.group.links.map((link, i) => (
      <FooterLink key={i} title={link.title} link={link.link} />
    ))}
  </div>
);

const Footer: React.FC = () => {
  return (
    <footer className="bg-white max-w-screen-lg p-4 sm:p-0 m-auto z-20">
      <div className="flex flex-col sm:flex-row">
        <div className="w-screen sm:w-2/3 flex flex-col justify-center sm:flex-row">
          <div className="mx-2 w-full">
            <FooterLinkGroup group={footerLinkGroups[0]} />
          </div>
          <div className="mx-2 w-full">
            <FooterLinkGroup group={footerLinkGroups[1]} />
          </div>
          <div className="mx-2 w-full">
            <FooterLinkGroup group={footerLinkGroups[2]} />
          </div>
        </div>
        <div className="w-full sm:w-1/3 justify-center m-auto mt-4 sm:m-auto">
          <SocialLinks />
        </div>
      </div>
      <div className="mx-0 sm:mx-8 my-12 flex m-auto">
        <p className="text-gray-500">
          © {new Date().getFullYear()} Teachers College Advancing Literacy
        </p>
      </div>
    </footer>
  );
};

export default Footer;
