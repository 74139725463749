import React from 'react';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SearchInputProps {
  placeholder: string;
  onChange?: any;
  withButton?: boolean;
  onClick?: any;
}

const SearchInput: React.FC<SearchInputProps> = props => {
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        if (!!props.onClick) {
          props.onClick();
        }
      }}
    >
      <label htmlFor="site-search" className="sr-only">Search</label>
      <input
        name="site-search"
        className="bg-gray-200 w-full rounded-full px-4 py-3 focus:outline-none p-4 sans-serif"
        placeholder={props.placeholder}
        onChange={props.onChange}
        id="site-search"
      />
      {props.withButton && (
        <button
          className="bg-gradient rounded-full shadow-md text-blue-100 sans-serif py-2 px-3 -ml-12 focus:outline-none"
          type="submit"
        >
          <span className="sr-only">Submit</span>
          <FontAwesomeIcon icon={faChevronCircleRight} />
        </button>
      )}
    </form>
  );
};

export default SearchInput;
