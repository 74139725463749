import React from 'react';
import { Link } from 'gatsby';

interface SubNavItemProps {
  label: string;
  to?: string;
  onMouseEnter?: any;
  onMouseLeave?: any;
}

const SubNavItem = (props: SubNavItemProps) => {
  return (
    <Link
      to={props.to ? props.to : '/'}
      className="text-primary hover:text-primary-dark hover:underline font-semibold w-full block p-3 text-left sans-serif"
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.label}
    </Link>
  );
};

export default SubNavItem;
