import React from 'react';
import { Link } from 'gatsby';

interface NavItemProps {
  label: string;
  to?: string;
  onMouseEnter?: any;
  onMouseLeave?: any;
}

const NavItem = (props: NavItemProps) => {
  return (
    <Link
      to={props.to ? props.to : '/'}
      className="text-primary hover:text-primary-dark hover:underline font-semibold py-5 sans-serif text-lg "
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.label}
    </Link>
  );
};

export default NavItem;
