import React from 'react';
import Card from '../common/Card';
import { XIcon } from '@heroicons/react/solid';
import EventSectionsForm, { EventSectionsCartModel } from './EventSectionsForm';

export const EVENT_SECTION_CART_DEFAULT = {
  items: [],
  display: false,
  updatedAt: new Date().toISOString()
};

const EventSectionsCart = ({
  setCart,
  cart,
  goToCheckout
}: {
  setCart: any;
  cart: EventSectionsCartModel;
  goToCheckout: () => void;
}) => {
  return (
    <div>
      {cart?.display && cart?.items?.length ? (
        <div className="fixed top-10 right-10 max-w-xs bg-white z-10 ">
          <Card>
            <div className="flex justify-end">
              <XIcon
                onClick={() =>
                  setCart({
                    ...cart,
                    display: false,
                    updatedAt: new Date().toISOString()
                  })
                }
                className="cursor-pointer"
                width={20}
              />
            </div>
            <h3 className="flex font-bold justify-center text-lg mb-2">
              Your order
            </h3>

            <div className="overflow-y-auto з2" style={{ maxHeight: '75vh' }}>
              <EventSectionsForm
                hideSubmit={false}
                cart={cart}
                setCart={setCart}
                onSubmit={goToCheckout}
                submitText="Checkout"
              />
            </div>
          </Card>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EventSectionsCart;
